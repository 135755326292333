import { taskEither } from 'fp-ts';
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { errorToReactLeft, pipe } from 'utils/fp';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { listTeamAppsTE, fetchOrgRolesTE, deleteTeamAppsTE, addTeamAppsTE, deleteTeamTE, listTeamsTE } from 'api/organizations';
import { ORG_ROLE_TYPE } from 'modules/Organizations/constants';
import { listAppsTE } from 'api/apps/listApps';
import { listUserOrgInvitationsTE } from 'api/organizations/listUserOrgInvitations';

export const apiEffects = {
  listOrgAppsTE: (orgId: string, params?: string): TaskEither<ReactLeft, CF.API.Apps.App[]> =>
    pipe(
      listAppsTE({ userId: orgId, params }, errorToReactLeft),
      taskEither.map((x) => x.apps),
    ),

  listTeamAppsTE: (orgId: string, teamId: string, params?: string): TaskEither<ReactLeft, CF.API.Organizations.TeamApp[]> =>
    pipe(
      listTeamAppsTE({ orgId, teamId, params }, errorToReactLeft),
      taskEither.map((x) => x.apps),
    ),

  fetchTeamRolesTE: (orgId: string, params?: string): TaskEither<ReactLeft, CF.API.Organizations.Role[]> =>
    pipe(
      fetchOrgRolesTE({ orgId, params }, errorToReactLeft),
      taskEither.map(({ roles }) => roles.filter((role) => role.type !== ORG_ROLE_TYPE)),
    ),

  deleteTeamAppsTE: (orgId: string, teamId: string, body: { app_ids: string[] }): TaskEither<ReactLeft, CF.API.BaseResponse> =>
    pipe(deleteTeamAppsTE({ orgId, teamId, body }, errorToReactLeft)),

  addTeamAppsTE: (
    orgId: string,
    teamId: string,
    body: { apps: { role_id: string; app_id: string }[] },
  ): TaskEither<ReactLeft, CF.API.Organizations.ListTeamMembersResponse> =>
    addTeamAppsTE(
      {
        orgId,
        teamId,
        body,
      },
      errorToReactLeft,
    ),

  deleteTeamTE: (orgId: string, body: { ids: string[] }): TaskEither<ReactLeft, CF.API.BaseResponse> =>
    pipe(deleteTeamTE({ orgId, body }, errorToReactLeft)),

  listUserOrgInvitationsTE: (userId: string = '') =>
    pipe(
      listUserOrgInvitationsTE({ userId }, errorToReactLeft),
      taskEither.map(({ invitations }) => invitations),
    ),
  listTeamsTE: (orgId: string, params?: string): TaskEither<ReactLeft, CF.API.Organizations.Team[]> =>
    pipe(
      listTeamsTE({ orgId, params }, errorToReactLeft),
      taskEither.map((x) => x.teams),
    ),
};
