import { ReactNodeArray } from 'react';
import { cx } from '@linaria/core';
import { useRouter } from 'next/router';
import { Link as NavLink } from 'components/Link';
import { getEntityVisibility } from 'modules/Listings/helpers/entityCardUtils';
import { Pill } from 'components/Pill';
import { pillSecondaryBase } from 'components/Pill/Pill_styles';
import { IconLock } from 'components/Icons';
import { Tooltip } from 'components/PlatformResourceSelector/Tooltip';
import { flex } from 'styles/utils';
import { Row, RowData } from '@tanstack/react-table';
import { link } from './Cells.styles';

type RowValueType = CF.API.Models.Model | CF.API.Workflows.Workflow | CF.API.Datasets.Dataset | CF.API.Modules.Module;
export interface CellLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  entity: 'models' | 'workflows' | 'datasets' | 'modules';
  value: string;
  showVisibility?: boolean;
  row: Row<RowData>;
  additionalPills?: ReactNodeArray;
}

export const Link = ({ entity, value, showVisibility, row, additionalPills, ...rest }: CellLinkProps): JSX.Element => {
  const { userOrOrgId, appId } = useRouter().query as { userOrOrgId: string; appId: string };
  const isPublic = showVisibility && getEntityVisibility(row.original as RowValueType) === 'PUBLIC';
  return (
    <NavLink href={`/${userOrOrgId}/${appId}/${entity}/${value}`} data-testid={`link-${value}`} className={cx(link, rest.className)} {...rest}>
      <Tooltip text={value}>
        <>{value}</>
      </Tooltip>

      <div className={cx(flex.init, flex.alignItems.center)}>
        {showVisibility && !isPublic && <IconLock size={18} color="#808080" data-testid="private-icon" />}

        {Array.isArray(additionalPills) &&
          additionalPills?.map((pillText) => (
            <Pill data-testid="pill-text" className={cx(pillSecondaryBase)}>
              {pillText}
            </Pill>
          ))}
      </div>
    </NavLink>
  );
};
