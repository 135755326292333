import React from 'react';
import { css, cx } from '@linaria/core';
export interface CalloutBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  background?: string;
}
export const calloutBadgeStyles = "omponents__Badge__CalloutBadge_tsx-calloutBadgeStyles-c1tihfqp";
export function CalloutBadge({
  color = '#306AFF',
  background = '#CCDCFB',
  style,
  children,
  className,
  ...calloutProps
}: CalloutBadgeProps): JSX.Element | null {
  return <span style={{
    color,
    background,
    ...style
  }} className={cx(calloutBadgeStyles, className)} {...calloutProps}>
      {children}
    </span>;
}

require("./CalloutBadge.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CalloutBadge.tsx");