export const UploadModelIcon = ({ width = 80, height = 80 }: { width: number | string; height: number | string }) => (
  <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="4" fill="#6938EF" />
    <path
      d="M55 45V47C55 49.8003 55 51.2004 54.455 52.27C53.9757 53.2108 53.2108 53.9757 52.27 54.455C51.2004 55 49.8003 55 47 55H33C30.1997 55 28.7996 55 27.73 54.455C26.7892 53.9757 26.0243 53.2108 25.545 52.27C25 51.2004 25 49.8003 25 47V45M48.3333 33.3333L40 25M40 25L31.6667 33.3333M40 25V45"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
