import React, { ReactNode, useCallback, useState } from 'react';
import { cx } from '@linaria/core';
import { Button } from 'components/ui_library/Button';
import { IconCloseRound } from 'components/Icons';
import { styles } from './ModelSelectorModalContainer.styles';
import { CustomModelIcon, HuggingFaceLogo, ImportModelIcon, PreTrainedModelIcon, UploadModelIcon } from './icons';

export interface IListOption {
  id: string;
  icon: ReactNode;
  title: ReactNode | string;
  description: string;
  isDisable?: boolean;
  dataTestId?: string;
}

export interface IModelOption {
  id: string;
  icon: ReactNode;
  title: ReactNode | string;
  description: ReactNode | string;
}

type Props = {
  title: string;
  options: Array<IListOption>;
  onCancelClick: () => void;
  onContinueClick: (option: IListOption) => void;
};

export const MODELS = {
  PRE_TRAINED: 'PRE_TRAINED',
  CUSTOM: 'CUSTOM',
  IMPORT: 'IMPORT',
  UPLOAD: 'UPLOAD',
};

export const MODEL_OPTIONS = [
  {
    id: MODELS.PRE_TRAINED,
    icon: <PreTrainedModelIcon width="100%" height="100%" />,
    title: 'Use a Pre-Trained Model',
    description: 'Explore and find pre-trained models for your use cases from Clarifai, OpenAI, Meta and more - curated by us for a head start.',
  },
  {
    id: MODELS.CUSTOM,
    icon: <CustomModelIcon width="100%" height="100%" />,
    title: 'Build a Custom Model',
    description: 'Custom train models from transfer-learning to fine-tuning, or create rule-based operators to chain models into workflows.',
  },
  {
    id: MODELS.IMPORT,
    icon: <ImportModelIcon width="100%" height="100%" />,
    title: (
      <div>
        Import Model from
        <HuggingFaceLogo />
      </div>
    ),
    description: 'One-click import of NLP, CV, Audio and Multi-modal tasks from Huggingface for easy customization and full managed inference.',
  },
  {
    id: MODELS.UPLOAD,
    icon: <UploadModelIcon width="100%" height="100%" />,
    title: 'Upload a Model',
    description: 'Upload locally trained models to our platform, we support common formats such as Tensorflow, Pytorch, ONNX.',
  },
];

export const ModelSelectorModalContainer: React.FC<Props> = ({ title, options, onCancelClick, onContinueClick }) => {
  const [selectedOption, setSelectedOption] = useState<IListOption>({} as IListOption);

  const onOptionClick = useCallback(
    (option) => {
      setSelectedOption(option);
      onContinueClick(option);
    },
    [onContinueClick],
  );

  return (
    <div className={styles}>
      <div className="modal-title">
        {title}
        <Button data-testid="add-model-close-button" variant="tertiary" onClick={() => onCancelClick()}>
          <IconCloseRound size={24} />
        </Button>
      </div>
      {options.map((option: IListOption) => {
        return (
          <Button
            disabled={selectedOption.isDisable}
            key={option.id}
            variant="unstyled"
            className={cx('option-card', option.isDisable && 'disabled-item ')}
            onClick={() => onOptionClick(option)}
            data-testid={option.id}
            aria-disabled={option.isDisable}
          >
            <div className="option-image">{option.icon}</div>
            <div className="option-title">{option.title}</div>
            <div className="option-description">{option.description}</div>
          </Button>
        );
      })}
    </div>
  );
};
