import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_MEMBER_MIN } from 'modules/Organizations/constants';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useOrgHasTeamRole } from 'modules/Organizations/hooks';
import { computeDocumentation } from 'modules/User/components/Settings/ComputeClusters/utils';

interface LinkInterface {
  text: string;
  url?: string;
  badge?: string;
  externalLink?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface AccountLinks {
  id: string;
  title?: string;
  links: LinkInterface[];
}

export function useAccountPopoverLinks(onLogout: React.MouseEventHandler<HTMLButtonElement>): AccountLinks[] {
  const isOrg = useIsOrgAccount();
  const userId = useCurrentUserId();
  const org = useCurrentUserOrg();

  return isOrg ? organizationAccountLinks(userId, onLogout, org?.role.id) : personalAccountLinks(userId, onLogout);
}

function personalAccountLinks(userId: string, onLogout: React.MouseEventHandler<HTMLButtonElement>): AccountLinks[] {
  const { nodePoolDeploymentsLDFF, computeMerchandisingLDFF, controlCenter } = useFeatureFlagsLD();
  const dynamicComputeUrl = nodePoolDeploymentsLDFF ? '/settings/compute' : computeDocumentation.marketingPage;

  return [
    {
      id: '0',
      title: 'Workspace',
      links: [
        {
          text: 'My Apps',
          url: `/${userId}`,
        },
        {
          text: 'Collaborations',
          url: `/${userId}/collaborations`,
        },
        ...(controlCenter
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
      ],
    },
    {
      id: '1',
      title: 'Settings',
      links: [
        {
          text: 'Account',
          url: '/settings',
        },
        ...(computeMerchandisingLDFF || nodePoolDeploymentsLDFF
          ? [
              {
                text: 'Compute',
                url: dynamicComputeUrl,
                badge: 'Preview',
                externalLink: !nodePoolDeploymentsLDFF,
              },
            ]
          : []),
        {
          text: 'Billing',
          url: '/settings/billing',
        },
        {
          text: 'Security',
          url: '/settings/security',
        },
        {
          text: 'Usage',
          url: '/settings/usage',
        },
        {
          text: 'Sign Out',
          handleClick: onLogout,
        },
      ],
    },
  ];
}

function organizationAccountLinks(orgId: string, onLogout: React.MouseEventHandler<HTMLButtonElement>, roleId?: string): AccountLinks[] {
  const isAdmin = roleId === ORGANIZATION_ROLE_ID_ADMIN;
  const isAdminOrMember = roleId !== ORGANIZATION_ROLE_ID_MEMBER_MIN;
  const orgHasTeamRole = useOrgHasTeamRole();

  const { nodePoolDeploymentsLDFF, controlCenter } = useFeatureFlagsLD();
  const dynamicComputeUrl = nodePoolDeploymentsLDFF ? '/settings/compute' : 'https://www.clarifai.com/solutions/compute-orchestration';

  return [
    {
      id: '0',
      title: 'Organization',
      links: [
        {
          text: 'Org Apps',
          url: `/${orgId}`,
        },
        ...(controlCenter && isAdmin
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
      ],
    },
    {
      id: '1',
      title: 'Settings',
      links: [
        {
          text: 'Account',
          url: '/settings/organization',
        },
        {
          text: 'Compute',
          url: dynamicComputeUrl,
          badge: 'Preview',
          externalLink: !nodePoolDeploymentsLDFF,
        },
        ...(isAdmin
          ? [
              {
                text: 'Security',
                url: '/settings/organization/security',
              },
            ]
          : []),
        ...(isAdminOrMember
          ? [
              {
                text: 'Members',
                url: '/settings/organization/members',
              },
            ]
          : []),
        ...(isAdminOrMember && orgHasTeamRole
          ? [
              {
                text: 'Teams',
                url: '/settings/organization/teams',
              },
            ]
          : []),
        {
          text: 'Sign Out',
          handleClick: onLogout,
        },
      ],
    },
  ];
}
