import { css } from '@linaria/core';
import { IconAWS } from 'components/Icons';

// HACK: This is temporary and should be done by the backend eventually
export const removeAcceleratorVendorPrefix = (acceleratorType: string[] | undefined) => {
  if (!acceleratorType) return '';
  return acceleratorType[0].replace('NVIDIA-', '');
};
export const computeDocumentation = {
  setUp: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/',
  createNodepool: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/#how-to-create-a-nodepool',
  instanceTypes: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/#instance-types',
  marketingPage: 'https://www.clarifai.com/products/compute-orchestration'
};
const providerOptionStyles = "odules__User__components__Settings__ComputeClusters__utils_tsx-providerOptionStyles-p1hrkfph";
const AWS_PROVIDER = {
  name: 'Amazon Web Services',
  component: <div className={providerOptionStyles}>
      <div className="provider-icon">
        <IconAWS size={20} />
      </div>
      <div className="provider-text">Amazon Web Services</div>
    </div>
};
const US_EAST_REGION = {
  name: 'N. Virginia US East',
  component: <div className={providerOptionStyles}>
      <div className="provider-icon">🇺🇸</div>
      <div className="provider-text">N. Virginia US East</div>
    </div>
};

// Add more providers and regions here when supported
export type ComputeProvider = string;
export type ComputeRegion = string;
type ComputeProviderReigonMap = Record<ComputeProvider, {
  name: string;
  component: JSX.Element;
}>;

// Instance Providers
export const COMPUTE_PROVIDER_MAP: ComputeProviderReigonMap = {
  aws: AWS_PROVIDER
};

// Provider Regions
export const COMPUTE_REGION_MAP: ComputeProviderReigonMap = {
  'us-east-1': US_EAST_REGION
};
export const getFormattedDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

require("./utils.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./utils.tsx");