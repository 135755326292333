import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useEffect } from 'react';
import type { RolesT } from '../constants';

interface userOrgProp {
  userOrg: CF.API.Organizations.UserOrganization;
}

interface defaultConfig {
  fallbackTo?: string;
  restrictedRoles?: Array<RolesT>;
}

export function useCurrentUserOrg(): CF.API.Organizations.UserOrganization | undefined {
  const { authData } = useAuthState();
  return authData?.organizations?.find((userOrg) => userOrg.organization.id === authData?.current_user_id);
}

export function withUserOrganization<P>(config?: defaultConfig): (c: FC<P & userOrgProp>) => FC<P> {
  return (Component: FC<P & userOrgProp>): FC<P> =>
    (props: P) => {
      const router = useRouter();
      const userOrg = useCurrentUserOrg();
      const { fallbackTo = '/explore', restrictedRoles = [] } = config || {};
      const isRestricted = restrictedRoles.includes(userOrg?.role.id as RolesT);

      // redirection happens if org account isn't selected or user's role is restricted
      useEffect(() => {
        if (!userOrg || isRestricted) {
          router.push(fallbackTo);
        }
      }, [userOrg, isRestricted]);

      return userOrg && !isRestricted ? <Component {...props} userOrg={userOrg} /> : null;
    };
}
