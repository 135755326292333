import { Popover, PopoverGroup, PopoverStateReturn, SegmentTitle, TextSmall } from 'components/Popover';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useClickAway } from 'react-use';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { noop } from 'utils/fp';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { hideBelow900, mobileNavbarDropdown } from './styles';
import { JiraButton } from './JiraButton';

export interface HelpPopoverContentProps {
  canReport?: boolean;
}
export interface HelpPopoverProps extends HelpPopoverContentProps {
  popover: PopoverStateReturn;
}

export interface MobileHelpItemsProps extends HelpPopoverContentProps {
  closePopup?: () => void;
}

export const CONTACT_US_LINK =
  'https://www.clarifai.com/company/contact?hsCtaTracking=6103e0be-65e4-4feb-942c-dc344db41e41%7C0b2d5cb9-56da-481e-b09c-b37fee70a135%C3%A5';

function HelpPopoverContents(props: HelpPopoverContentProps): JSX.Element {
  const { track } = useDataCollection();
  const { changelog } = useFeatureFlagsLD();

  const onHelpMenuClick = (btnText: string): void => {
    track('Help Menu Clicked', { param1: 'main_header', param2: btnText });
  };

  return (
    <>
      <PopoverGroup>
        <SegmentTitle>HELP</SegmentTitle>
        <a href={CONTACT_US_LINK} target="_blank" onClick={() => onHelpMenuClick('Contact Us')} rel="noreferrer">
          <TextSmall className="bold">Contact Us</TextSmall>
        </a>
        <a href="https://docs.clarifai.com" onClick={() => onHelpMenuClick('Documentation')} target="_blank" rel="noreferrer">
          <TextSmall className="bold">Documentation</TextSmall>
        </a>
        <a
          href="https://docs.clarifai.com/clarifai-basics/community"
          onClick={() => onHelpMenuClick('Create Guide')}
          target="_blank"
          rel="noreferrer"
        >
          <TextSmall className="bold">Quick Start Guide</TextSmall>
        </a>
        <a href="https://status.clarifai.com" onClick={() => onHelpMenuClick('API Status')} target="_blank" rel="noreferrer">
          <TextSmall className="bold">API Status</TextSmall>
        </a>
        <a
          href="https://discord.com/invite/26upV8Y4Nd"
          data-testid="join-discord"
          onClick={() => onHelpMenuClick('Join our Discord Channel')}
          target="_blank"
          rel="noreferrer"
        >
          <TextSmall className="bold">Join our Discord Channel</TextSmall>
        </a>
        <a
          href="https://portal.productboard.com/clarifai/1-clarifai-product-portal"
          data-testid="product-roadmap"
          onClick={() => onHelpMenuClick('Product Roadmap')}
          target="_blank"
          rel="noreferrer"
        >
          <TextSmall className="bold">Product Roadmap</TextSmall>
        </a>
      </PopoverGroup>
      {props.canReport && (
        <PopoverGroup>
          <SegmentTitle>REPORT</SegmentTitle>
          <JiraButton collectorId="0b68141c" clickTargetSelector="#atlwdg-trigger.atlwdg-RIGHT">
            <TextSmall onClick={() => onHelpMenuClick('Report a Bug')}>Report a Bug</TextSmall>
          </JiraButton>
        </PopoverGroup>
      )}
      {changelog && (
        <PopoverGroup>
          <SegmentTitle>Latest Release Notes</SegmentTitle>
          <a
            href="https://docs.clarifai.com/product-updates/changelog"
            onClick={() => onHelpMenuClick('Latest Release Notes')}
            target="_blank"
            rel="noreferrer"
          >
            <TextSmall> {changelog}</TextSmall>
          </a>
        </PopoverGroup>
      )}
    </>
  );
}

export function MobileHelpItems({ canReport, closePopup = noop }: MobileHelpItemsProps): JSX.Element {
  const contentRef = useRef(null);

  useClickAway(contentRef, closePopup);

  return (
    <div className={mobileNavbarDropdown}>
      <motion.div ref={contentRef} initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ opacity: 0, height: 0 }} className="content">
        <HelpPopoverContents canReport={canReport} />
      </motion.div>

      <button type="button" aria-label="backdrop" className="backdrop" onClick={closePopup} />
    </div>
  );
}

export function HelpPopover(props: HelpPopoverProps): JSX.Element | null {
  return (
    <Popover aria-label="Help" popover={props.popover} className={hideBelow900}>
      <HelpPopoverContents canReport={props.canReport} />
    </Popover>
  );
}
