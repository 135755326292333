import { useRouter } from 'next/router';
import { Link } from 'components/Link';
import { Popover, PopoverGroup, SegmentTitle, TextSmall, PopoverStateReturn } from 'components/Popover';
import { AccountSelector } from 'modules/Organizations/components/AccountSelector';
import React, { useRef } from 'react';
import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { Button } from 'components/ui_library/Button';
import { motion } from 'framer-motion';
import { noop } from 'utils/fp';
import { useClickAway } from 'react-use';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { CalloutBadge } from 'components/Badge/CalloutBadge';
import { useAccountPopoverLinks } from './AccountPopover.hooks';
import { accountPopover, accountMenuItem, accountMenuGroup, accountMenuGroupTitle, mobileNavbarDropdown } from './styles';

export interface DesktopAccountItemsProps {
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
  closePopup?: () => void;
}

export interface MobileAccountItemsProps {
  onLogout: () => void;
  canCreateApp: boolean;
  handleCreateApp: () => void;
  closePopup?: () => void;
}

export interface AccountPopoverProps extends Omit<DesktopAccountItemsProps, 'closePopup'> {
  popover: PopoverStateReturn;
}

export interface PopoverLinkProps {
  href: string;
  text: string;
  className?: string;
  badge?: string | undefined;
  externalLink?: boolean;
}

const PopoverLink: React.FC<PopoverLinkProps> = ({ href, text, className, badge, externalLink }) => {
  return (
    <Link className={className} href={href} target={externalLink ? '_blank' : undefined}>
      <TextSmall>{text}</TextSmall>
      {badge && (
        <CalloutBadge color="#b54708" background="#fef0c7">
          {badge}
        </CalloutBadge>
      )}
    </Link>
  );
};

const PopoverButton: React.FC<{ onClick?: React.MouseEventHandler<HTMLSpanElement>; text: string; className: string; badge: string | undefined }> = ({
  onClick,
  text,
  className,
  badge,
}) => (
  <div className={className} style={{ display: 'flex', alignItems: 'center' }}>
    <TextSmall role="button" onClick={onClick}>
      {text}
    </TextSmall>
    {badge && (
      <CalloutBadge color="#b54708" background="#fef0c7">
        {badge}
      </CalloutBadge>
    )}
  </div>
);

export const MobileAccountItems: React.FC<MobileAccountItemsProps> = ({ onLogout, closePopup = noop, canCreateApp, handleCreateApp }) => {
  const path = usePathWithoutQueryParams();
  const isOrg = useIsOrgAccount();
  const userId = useCurrentUserId();
  const contentRef = useRef(null);

  const { nodePoolDeploymentsLDFF } = useFeatureFlagsLD();

  const retractNavbarAndCreateApp = (): void => {
    handleCreateApp();
    closePopup();
  };

  const retractNavbarAndLogout = (): void => {
    onLogout();
    closePopup();
  };

  const linksWithText = [
    { href: `/${userId}/apps`, text: isOrg ? 'Org Apps' : 'My Apps' },
    ...(nodePoolDeploymentsLDFF ? [{ href: '/settings/compute', text: 'Compute' }] : []),
    { href: `/explore`, text: 'Community' },
    { href: isOrg ? '/settings/organization' : '/settings', text: 'Account Settings' },
  ];

  useClickAway(contentRef, closePopup);

  return (
    <div className={mobileNavbarDropdown}>
      <motion.div ref={contentRef} initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ opacity: 0, height: 0 }} className="content">
        <AccountSelector />
        <div>
          <div className="list">
            {canCreateApp && (
              <Button variant="unstyled" onClick={retractNavbarAndCreateApp}>
                Create an App
              </Button>
            )}
            {linksWithText.map(({ href, text }) => (
              <Link key={href} href={href} className={path === href ? 'active' : undefined}>
                {text}
              </Link>
            ))}
            <div className="divider" />
            <Button variant="unstyled" onClick={retractNavbarAndLogout}>
              Sign Out
            </Button>
          </div>
        </div>
      </motion.div>

      <button type="button" aria-label="backdrop" className="backdrop" onClick={closePopup} />
    </div>
  );
};

export const DesktopAccountItems: React.FC<DesktopAccountItemsProps> = ({ onLogout, closePopup }) => {
  const path = usePathWithoutQueryParams();
  const sections = useAccountPopoverLinks(onLogout);

  return (
    <>
      <PopoverGroup style={{ borderTop: 0, paddingTop: 0 }}>
        <AccountSelector />
      </PopoverGroup>
      {sections.map(({ id, links, title }) => {
        return (
          <PopoverGroup key={id} className={accountMenuGroup}>
            {title && <SegmentTitle className={accountMenuGroupTitle}>{title}</SegmentTitle>}
            {links.map(({ text, handleClick, url, badge, externalLink }) => {
              return url && url !== path ? (
                <PopoverLink className={accountMenuItem} href={url} text={text} badge={badge} key={`${id}_${url}`} externalLink={externalLink} />
              ) : (
                <PopoverButton className={accountMenuItem} text={text} badge={badge} onClick={handleClick || closePopup} key={`${id}_${url}`} />
              );
            })}
          </PopoverGroup>
        );
      })}
    </>
  );
};

export const AccountPopover: React.FC<AccountPopoverProps> = ({ popover, onLogout }): JSX.Element => {
  const closePopup = (): void => {
    popover.hide();
  };

  return (
    <Popover data-testid="account-popover" aria-label="Account" popover={popover} className={accountPopover}>
      <DesktopAccountItems onLogout={onLogout} closePopup={closePopup} />
    </Popover>
  );
};

export function usePathWithoutQueryParams() {
  const { asPath } = useRouter();
  return asPath?.split('?')[0] || '';
}
