import { ReactNode, useCallback } from 'react';
import { either, taskEither } from 'fp-ts';
import cogoToast from 'cogo-toast';
import { errorToReactLeft, noop, pipe } from 'utils/fp';
import { queryKeys, useInfiniteQueryTE, useMutationTE, useQueryTE } from 'utils/react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { addTeamAppsTE, fetchOrgRolesTE } from 'api/organizations';
import { useCurrentUserId, useSwitchUserAccount } from 'modules/Auth/hooks';
import { useModalActions } from 'components/ModalManager/Context';
import type { TOrganizationsMST } from 'modules/Organizations/mstStores/OrganizationMST';
import { InviteModal, DeleteModal } from 'modules/Organizations/components/Modals';
import { useQueryClient } from 'react-query';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { onboardingDialogClassName } from 'modules/Auth/components/OnboardingDialog/OnboardingDialog.styles';
import { includes } from 'rambda';
import nProgress from 'nprogress';
import { CreateOrganization } from './components/CreateOrganization';
import { ORGANIZATION_ROLE_ID_MEMBER_MIN, ORG_ROLE_TYPE } from './constants';
import { apiEffects } from './components/TeamDetails/apiEffect';

const DELETE_TEAM_MODAL_ID = 'delete_team_dialog';
const DELETE_INVITE_MODAL_ID = 'delete_invite_dialog';
const REMOVE_MEMBER_MODAL_ID = 'remove_member_dialog';
const INVITE_MODAL_ID = 'invite_members_dialog';
const CREATE_ORG_MODAL_ID = 'create_organization_modal';

type RoleOptionList = { value: string; name: string }[];

export function hasTeamRole(roles: CF.API.Organizations.Role[]) {
  return includes(
    ORGANIZATION_ROLE_ID_MEMBER_MIN,
    roles.map((role) => role.id),
  );
}

// Use this hook sparingly unless you need to explicitly need more role
// related details that useOrgRoles() hook doesn't show.
export function useFetchOrgRoles(type: CF.API.Organizations.RoleType) {
  const orgId = useCurrentUserId();

  const { data } = useQueryTE(
    [queryKeys.OrgRoles, { userOrOrgId: orgId, type }],
    fetchOrgRolesTE({ orgId, params: `types=${type}` }, errorToReactLeft),
    {
      enabled: Boolean(orgId),
      retry: 5,
    },
  );

  return pipe(
    data,
    either.map(({ roles }) => roles),
  );
}

// Lists all org roles to be shown/used in UI. If you need to show more details
// of each Role, then use useFetchOrgRoles() hook.
export function useOrgRoles(type: CF.API.Organizations.RoleType) {
  const rolesE = useFetchOrgRoles(type);

  return pipe(
    rolesE,
    either.map((roles) => roles.map((role) => ({ value: role.id, name: role.name }))),
    either.getOrElse<ReactLeft, RoleOptionList>(() => []),
  );
}

// Checks if Organization has a role of Team member. This hook is used to
// determine if the Org has access to Team related features. If you need to
// check for a page on SSR, then check out withHasTeamAccessSsr().
export function useOrgHasTeamRole() {
  const rolesE = useFetchOrgRoles(ORG_ROLE_TYPE);
  return pipe(
    rolesE,
    either.map(hasTeamRole),
    either.getOrElse(() => false),
  );
}

export function useInviteModal(store: TOrganizationsMST): () => void {
  const { openModal, closeModal } = useModalActions();

  const onClose = (): void => {
    closeModal({ id: INVITE_MODAL_ID });
  };

  return useCallback((): void => {
    openModal({
      id: INVITE_MODAL_ID,
      title: '',
      content: <InviteModal store={store} onClose={onClose} />,
      makeActions: () => null,
    });
  }, [openModal, store]);
}

export function useDeleteInviteModal({ store }: { store: TOrganizationsMST }): (invitationId: string) => void {
  const { openModal, closeModal } = useModalActions();
  const orgId = useCurrentUserId();

  const onClose = (): void => {
    closeModal({ id: DELETE_INVITE_MODAL_ID });
  };

  return useCallback((invitationId: string) => {
    openModal({
      id: DELETE_INVITE_MODAL_ID,
      title: '',
      content: (
        <DeleteModal
          title="Delete Invitation?"
          message="Are you sure you want to cancel invitation?"
          onDelete={() => store.deleteInvitation({ orgId, invitationId })}
          onClose={onClose}
        />
      ),
      makeActions: () => null,
    });
  }, []);
}

export function useRemoveMemberModal({ store }: { store: TOrganizationsMST }): (memberId: string) => void {
  const { openModal, closeModal } = useModalActions();
  const orgId = useCurrentUserId();

  const getMemberName = (memberId: string): string =>
    pipe(
      store.listMembers,
      either.fold(
        () => '',
        (members) => members.find((member) => member.user_id === memberId)?.name || '',
      ),
    );

  const onClose = (): void => {
    closeModal({ id: REMOVE_MEMBER_MODAL_ID });
  };

  return useCallback((memberId: string) => {
    const memberName = getMemberName(memberId);

    openModal({
      id: REMOVE_MEMBER_MODAL_ID,
      title: '',
      content: (
        <DeleteModal
          title={`Remove member ${memberName}?`}
          message={`Are you sure you want to remove member ${memberName}?`}
          onDelete={() => store.removeMember({ orgId, memberId })}
          onClose={onClose}
        />
      ),
      makeActions: () => null,
    });
  }, []);
}

export function useDeleteTeam(orgId: string): (teamId: string, onComplete?: () => void) => void {
  const { openModal, closeModal } = useModalActions();
  const queryClient = useQueryClient();

  const onClose = (): void => closeModal({ id: DELETE_TEAM_MODAL_ID });

  return useCallback(
    (teamId: string, onComplete?: () => void) => {
      openModal({
        id: DELETE_TEAM_MODAL_ID,
        title: '',
        content: (
          <DeleteModal
            title="Do you want to delete this team?"
            message={
              <>
                Are you sure you want to delete the team? <br /> This operation cannot be undone.
              </>
            }
            onDelete={pipe(
              apiEffects.deleteTeamTE(orgId, { ids: [teamId] }),
              taskEither.mapLeft((x) => {
                cogoToast.error(x?.props?.reason || 'There was an error deleting team.', { heading: 'Error' });
                onClose();
                return x;
              }),
              taskEither.map((x) => {
                cogoToast.success(`Team deleted successfully!`);
                onClose();
                queryClient.invalidateQueries([queryKeys.Teams, { orgId }]);
                if (onComplete) onComplete();
                return x;
              }),
            )}
            onClose={onClose}
            btnText="Yes, delete this team"
            hideAlert
          />
        ),
        makeActions: () => null,
      });
    },
    [orgId, openModal, closeModal],
  );
}

export function useDeleteAppsFromTeam({
  orgId,
  modal: {
    title = 'Do you want to delete these apps from this team?',
    message = (
      <>
        Are you sure you want to delete the apps from the team? <br /> This operation cannot be undone.
      </>
    ),
    btnText = 'Yes, delete these apps',
  } = {},
  toast: { success = 'Apps deleted successfully!', error = 'There was an error deleting apps.' } = {},
}: {
  orgId: string;
  modal?: {
    title?: string;
    message?: string | ReactNode;
    btnText?: string;
  };
  toast?: {
    success?: string;
    error?: string;
  };
}): (teamId: string, appId: string, onComplete?: () => void) => void {
  const { openModal, closeModal } = useModalActions();
  const queryClient = useQueryClient();

  const onClose = (): void => closeModal({ id: DELETE_TEAM_MODAL_ID });

  return useCallback(
    (teamId: string, appId: string, onComplete?: () => void) => {
      openModal({
        id: DELETE_TEAM_MODAL_ID,
        title: '',
        content: (
          <DeleteModal
            title={title}
            message={typeof message === 'function' ? message?.({ teamId, appId }) : message}
            onDelete={pipe(
              apiEffects.deleteTeamAppsTE(orgId, teamId, { app_ids: [appId] }),
              taskEither.mapLeft((x) => {
                cogoToast.error(x?.props?.reason || error, { heading: 'Error' });
                onClose();
                return x;
              }),
              taskEither.map((x) => {
                cogoToast.success(success);
                onClose();
                queryClient.invalidateQueries([queryKeys.Teams, { orgId }]);
                if (onComplete) onComplete();
                return x;
              }),
            )}
            onClose={onClose}
            btnText={btnText}
            hideAlert
          />
        ),
        makeActions: () => null,
      });
    },
    [orgId, openModal, closeModal],
  );
}

export function useOrganizationInvitesList() {
  const { authData } = useAuthState();

  const { data: orgInvitesListE } = useQueryTE(
    [queryKeys.UserOrganizationInvitations, { userId: authData?.user_id }],
    apiEffects.listUserOrgInvitationsTE(authData?.user_id),
    { enabled: Boolean(authData?.is_email_verified) },
  );

  return pipe(
    orgInvitesListE,
    either.getOrElse(() => [] as CF.API.Organizations.OrgMemberInvite[]),
  );
}

export function useOpenCreateOrgModal() {
  const { openModal, closeModal } = useModalActions();
  const switchUserAccount = useSwitchUserAccount();
  const { load } = useCurrentStore();

  const afterOrgCreate = useCallback(
    (org: CF.API.Organizations.Organization) => {
      load();
      switchUserAccount(org.id);
    },
    [load, switchUserAccount],
  );

  return useCallback(
    () =>
      openModal({
        id: CREATE_ORG_MODAL_ID,
        title: '',
        content: <CreateOrganization closeAction={() => closeModal({ id: CREATE_ORG_MODAL_ID })} orgCreateCallback={afterOrgCreate} />,
        makeActions: noop,
        className: onboardingDialogClassName,
        contentWrapperClassName: 'fullHeight',
      }),
    [openModal],
  );
}

export function useTeamsList(orgId: string, perPage = 20) {
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQueryTE(
    [queryKeys.Teams, { orgId }],
    ({ pageParam = 1 }) => apiEffects.listTeamsTE(orgId, `page=${pageParam}&per_page=${perPage}`),
    {
      retry: true,
      getNextPageParam: (lastPage_, allPages) =>
        pipe(
          lastPage_,
          either.fold(
            () => undefined,
            (lastPage): number | undefined => (lastPage.length < perPage ? undefined : allPages.length + 1),
          ),
        ),
    },
  );

  return { data, isFetching, hasNextPage, fetchNextPage };
}

export function useAddTeamAppsMutation({ orgId, teamId, closeAction }: { orgId: string; teamId?: string; closeAction: () => void }) {
  const queryClient = useQueryClient();
  return useMutationTE(
    ({ apps, teamId_ }: { apps: { app_id: string; role_id: string }[]; teamId_: string }) =>
      pipe(
        addTeamAppsTE(
          {
            orgId,
            teamId: teamId_ ?? teamId,
            body: {
              apps,
            },
          },
          errorToReactLeft,
        ),
      )(),
    {
      onMutate: () => {
        nProgress.start();
      },
      onSettled: () => {
        nProgress.done();
      },
      onSuccess: () => {
        cogoToast.success(`Apps(s) added to the team.`);
        queryClient.invalidateQueries([queryKeys.TeamApps, { orgId, teamId }]);
        queryClient.invalidateQueries(['AllTeamApps', { orgId, teamId }]);
        closeAction();
      },
      onError: ({ props }) => {
        cogoToast.error(props?.reason || 'There was an error adding app(s) to team.', { heading: 'Error' });
      },
    },
  );
}
