import { cloneElement } from 'react';
import type { ComponentProps } from 'types';
import { useTooltipState, Tooltip as ReakitTooltip, TooltipReference, TooltipArrow, TooltipInitialState } from 'reakit/Tooltip';

export interface TooltipArrowProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
}

export interface TooltipProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  children: React.ReactElement;
  data: React.ReactChild;
  initialState?: TooltipInitialState;
  showArrow?: boolean;
  arrowProps?: TooltipArrowProps;
}

const TooltipInternal: React.FC<TooltipProps> = ({ children, data, initialState, arrowProps, ...props }) => {
  const tooltip = useTooltipState({
    ...initialState,
    placement: (initialState && initialState.placement) || 'bottom',
    animated: initialState?.animated ?? 250,
  });

  return (
    <>
      <TooltipReference {...tooltip} {...children.props}>
        {(referenceProps) => cloneElement(children, referenceProps)}
      </TooltipReference>
      <ReakitTooltip {...tooltip} {...props}>
        {data}
        {props.showArrow && !props.hidden && <TooltipArrow {...tooltip} {...arrowProps} />}
      </ReakitTooltip>
    </>
  );
};

TooltipInternal.defaultProps = {
  initialState: { placement: 'left-start' },
};

// see https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/default_props/
export const Tooltip = (props: ComponentProps<typeof TooltipInternal>): JSX.Element => {
  return <TooltipInternal {...props} />;
};
