import { cx } from '@linaria/core';
import { IconAlertTriangleFilled } from 'components/Icons/IconAlertTriangleFilled';
import { success, error, info, caution, alertIconCls } from './Alert.styles';

export type AlertStatus = 'success' | 'error' | 'info' | 'caution';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  type: AlertStatus;
  showIcon?: boolean;
}

const AlertMap = {
  success,
  error,
  info,
  caution,
};

export const Alert = ({ className, type, showIcon = true, ...rest }: AlertProps): JSX.Element => (
  <div className={cx(className, AlertMap[type])} data-alert-type={type} {...rest}>
    {type === 'caution' && showIcon && <IconAlertTriangleFilled height={12.5} width={14} className={alertIconCls} />}
    {rest.children}
  </div>
);
