import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchApi, fetchTE } from 'utils/request';

export const fetchTeamTE = <E = CF.API.ApiError>(
  { orgId, teamId }: { orgId?: string; teamId: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Organizations.FetchTeamResponse> =>
  fetchTE<CF.API.Organizations.FetchTeamResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams/${teamId}`,
      method: 'GET',
    },
    onRejected,
  );

export interface IFetchTeamResponse {
  status: CF.API.Status;
  team_roles: {
    team: CF.API.Organizations.Team;
    role: Pick<CF.API.Organizations.Role, 'id' | 'name'>;
    created_at: string;
  }[];
}

export const fetchTeamsByOrgIdAndAppId = ({ orgId, appId }: { orgId: string; appId: string }): Promise<IFetchTeamResponse> =>
  fetchApi({
    path: `/api/_api/v2/organizations/${orgId}/apps/${appId}/teams`,
    method: 'GET',
  });
