import { Field, FieldProps, FieldConfig } from 'formik';
import { Input, InputProps } from 'components/Input';
import { css, cx } from '@linaria/core';
import React, { useCallback } from 'react';
import { LabelWithTooltip, TooltipText } from 'components/Formik/LabelWithTooltip';
import { fieldWrapper, formLabel } from 'components/Formik/Forms.styles';
import { FormGroup } from 'components/Formik/FormGroup';
import { FormFieldError } from 'components/Formik/FormFieldError';
import { SelectOption } from 'components/Select';
import { RadioGroup, useRadioState } from 'components/RadioGroup';
import { theming } from 'styles/utils';
import { useBoolean } from 'react-use';
import { cssHelpers } from 'styles/utils/core';
type ChipOptionsFieldProps = {
  options: SelectOption[];
  errorClassName?: string;
  label?: string;
  value?: string;
  tooltip?: {
    title: string;
    text: TooltipText;
  };
  formGroupClassName?: string;
  truncateError?: boolean;
  inputLabelClassName?: string;
  asteriskClassName?: string;
  // Shows an optional input field when the specific option key is selected
  withOtherOptionFieldKey?: string;
} & Omit<InputProps, 'form'>;
const ChipOptionsField_: React.FC<ChipOptionsFieldProps & FieldProps> = ({
  errorClassName,
  field,
  tooltip,
  label,
  meta,
  options,
  formGroupClassName,
  className,
  id,
  truncateError,
  inputLabelClassName,
  asteriskClassName,
  withOtherOptionFieldKey,
  form,
  ...restInputProps
}) => {
  const error = meta.error;
  const isErrored = meta.touched && error;
  const idForLabel = id || label || field.name; // fallback to other options to always ensure having a valid htmlFor id for the label
  const radioState = useRadioState({
    baseId: idForLabel,
    state: field.value || restInputProps.value
  });
  const [isOtherOptionSelected, setOtherOptionSelected] = useBoolean(false);
  return <FormGroup className={formGroupClassName}>
      <div className={cx(fieldWrapper)}>
        <LabelWithTooltip className={cx(formLabel, inputLabelClassName)} htmlFor={idForLabel} tooltipTitle={tooltip?.title} tooltipText={tooltip?.text}>
          {label} {restInputProps.required ? <span className={cx('asterisk', asteriskClassName)}>*</span> : null}
        </LabelWithTooltip>
        <RadioGroup radio={radioState} options={options.map(({
        name,
        value
      }) => ({
        label: name,
        value
      }))} className={cx(chipOptionsRadioGroup, className)} {...restInputProps} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (withOtherOptionFieldKey && withOtherOptionFieldKey === value) {
          setOtherOptionSelected(true);
          form.setFieldValue(field.name, '');
          restInputProps.onChange?.({
            ...e,
            target: {
              ...e.target,
              value: ''
            }
          });
          return;
        }
        setOtherOptionSelected(false);
        restInputProps.onChange?.(e);
      }} />
        {isOtherOptionSelected && <Input placeholder="Write your goal..." className={cssHelpers.margin.yt.half} autoFocus onChange={e => {
        restInputProps.onChange?.(e);
        form.setFieldValue(field.name, e.target.value);
      }} />}
        {isErrored && <FormFieldError className={errorClassName} error={error} truncateError={truncateError} />}
      </div>
    </FormGroup>;
};
export function ChipOptionsField({
  className,
  ...props
}: ChipOptionsFieldProps & Omit<FieldConfig, 'component' | 'children'>): JSX.Element {
  /* eslint-disable react/no-children-prop */
  // avoid field rerendering on parent rerender
  const children = useCallback((fieldProps: FieldProps) => <ChipOptionsField_ className={cx(className)} {...fieldProps} {...props} onChange={event => {
    // Formik's `onChange`.
    fieldProps.field.onChange(event);
    // User-defined `onChange`.
    props.onChange?.(event);
  }} />, [props]);
  return <Field {...props} children={children} />;
}
const chipOptionsRadioGroup = "omponents__Formik__ChipOptionsField_tsx-chipOptionsRadioGroup-c1k59oxm";

require("./ChipOptionsField.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./ChipOptionsField.tsx");