import { IconProps, defaultIconProps } from './IconProps';

export const IconAlertTriangleFilled: React.FC<IconProps> = ({ height = '13', width = '14', color, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13.8001 10.7556L8.33444 1.26376C8.19785 1.03121 8.00287 0.838397 7.76881 0.704423C7.53476 0.570449 7.26975 0.499969 7.00006 0.499969C6.73037 0.499969 6.46536 0.570449 6.23131 0.704423C5.99725 0.838397 5.80227 1.03121 5.66569 1.26376L0.20006 10.7556C0.0686451 10.9806 -0.000610352 11.2364 -0.000610352 11.4969C-0.000610352 11.7574 0.0686451 12.0132 0.20006 12.2381C0.334892 12.4721 0.529541 12.666 0.764036 12.7998C0.99853 12.9337 1.26442 13.0028 1.53444 13H12.4657C12.7355 13.0026 13.0011 12.9334 13.2354 12.7995C13.4696 12.6656 13.6641 12.4719 13.7988 12.2381C13.9304 12.0133 13.9999 11.7576 14.0001 11.4971C14.0003 11.2366 13.9313 10.9807 13.8001 10.7556ZM6.50006 5.50001C6.50006 5.3674 6.55274 5.24023 6.64651 5.14646C6.74027 5.05269 6.86745 5.00001 7.00006 5.00001C7.13267 5.00001 7.25985 5.05269 7.35361 5.14646C7.44738 5.24023 7.50006 5.3674 7.50006 5.50001V8.00001C7.50006 8.13262 7.44738 8.2598 7.35361 8.35356C7.25985 8.44733 7.13267 8.50001 7.00006 8.50001C6.86745 8.50001 6.74027 8.44733 6.64651 8.35356C6.55274 8.2598 6.50006 8.13262 6.50006 8.00001V5.50001ZM7.00006 11C6.85172 11 6.70672 10.956 6.58338 10.8736C6.46005 10.7912 6.36392 10.6741 6.30715 10.537C6.25038 10.4 6.23553 10.2492 6.26447 10.1037C6.29341 9.95821 6.36484 9.82457 6.46973 9.71968C6.57462 9.61479 6.70826 9.54336 6.85374 9.51442C6.99923 9.48548 7.15003 9.50034 7.28707 9.5571C7.42412 9.61387 7.54125 9.71 7.62366 9.83333C7.70607 9.95667 7.75006 10.1017 7.75006 10.25C7.75006 10.4489 7.67104 10.6397 7.53039 10.7803C7.38974 10.921 7.19897 11 7.00006 11Z"
      fill={color === 'currentColor' ? '#F79009' : color}
    />
  </svg>
);

IconAlertTriangleFilled.defaultProps = defaultIconProps;
